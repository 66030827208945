import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import GptBitcoin from './GptBitcoin'; // Your first Notion page
import GptBitcoinPaid from './GptBitcoinPaid'; // Your second Notion page
import GptBitcoinBithumb from './GptBitcoinBithumb'; // Your third Notion page
import BitcoinFuture from './BitcoinFuture';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/gptbitcoin" element={<GptBitcoin />} />
          <Route path="/gptbitcoin-paid" element={<GptBitcoinPaid />} />
          <Route path="/gptbitcoin-bithumb" element={<GptBitcoinBithumb />} />
          <Route path="/bitcoin-future" element={<BitcoinFuture />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;